<template>
  <div>
    <component
      v-for="(formfield, index) in formFields"
      :is="formfield.component"
      :key="'formField' + index"
      :label="formfield.label"
      :fieldType="formfield.fieldType"
      v-model="formfield.value"
      :error="formfield.error"
    ></component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSaving: false,
      newProfile: {
        Id: 0,
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        SendMail: false
      },
      formFields: null
    };
  },

  created() {
    this.formFields = {
      firstName: {
        component: "ui-base-input-field",
        label: "First name",
        fieldType: "text",
        value: this.newProfile.FirstName,
        error: "",
        required: true
      },
      lastName: {
        component: "ui-base-input-field",
        label: "Last name",
        fieldType: "text",
        value: this.newProfile.LastName,
        error: "",
        required: true
      },
      email: {
        component: "ui-base-input-field",
        label: "E-mail",
        fieldType: "email",
        value: this.newProfile.Email,
        error: "",
        required: true
      },
      phone: {
        component: "ui-base-input-field",
        label: "Phone",
        fieldType: "text",
        value: this.newProfile.Phone,
        error: "",
        required: true
      },
      sendMail: {
        component: "ui-checkbox",
        label: "Send mail",
        fieldType: "checkbox",
        value: this.newProfile.SendMail,
        error: "",
        required: false
      }
    };
  }
};
</script>
